module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Douglas Meehan","short_name":"dm","start_url":"/","background_color":"#63C9D7","theme_color":"#63C9D7","display":"minimal-ui","icon":"src/images/contour_bg.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e53628733c62f0de04a5cecde42a08b9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":940},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
